// ----------------------------------------------------------------------
// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.
// ----------------------------------------------------------------------
var cn = {
    demo: {
        title: "Chinese",
        introduction: "Lorem Ipsum \u53EA\u662F\u5370\u5237\u548C\u6392\u7248\u884C\u4E1A\u7684\u865A\u62DF\u6587\u672C\u3002 \u81EA 1500 \u5E74\u4EE3\u4EE5\u6765\uFF0CLorem Ipsum \u4E00\u76F4\u662F\u884C\u4E1A\u7684\u6807\u51C6\u865A\u62DF\u6587\u672C\uFF0C\u5F53\u65F6\u4E00\u4F4D\u4E0D\u77E5\u540D\u7684\u5370\u5237\u5546\u91C7\u7528\u4E86\u4E00\u79CD\u7C7B\u578B\u7684\u53A8\u623F\u5E76\u5C06\u5176\u52A0\u6270\u4EE5\u5236\u4F5C\u7C7B\u578B\u6837\u672C\u4E66\u3002 \u5B83\u4E0D\u4EC5\u7ECF\u5386\u4E86\u4E94\u4E2A\u4E16\u7EAA\uFF0C\u800C\u4E14\u7ECF\u5386\u4E86\u7535\u5B50\u6392\u7248\u7684\u98DE\u8DC3\uFF0C\u57FA\u672C\u4FDD\u6301\u4E0D\u53D8\u3002 \u5B83\u5728 1960 \u5E74\u4EE3\u968F\u7740\u5305\u542B Lorem Ipsum \u6BB5\u843D\u7684 Letraset \u8868\u7684\u53D1\u5E03\u800C\u6D41\u884C\u8D77\u6765\uFF0C\u6700\u8FD1\u8FD8\u968F\u7740 Aldus PageMaker \u7B49\u684C\u9762\u51FA\u7248\u8F6F\u4EF6\uFF08\u5305\u62EC Lorem Ipsum \u7684\u7248\u672C\uFF09\u800C\u666E\u53CA.",
    },
    docs: {
        hi: "\u4F60\u597D",
        description: "\u9700\u8981\u5E2E\u5FD9\uFF1F\n \u8BF7\u67E5\u770B\u6211\u4EEC\u7684\u6587\u6863.",
        documentation: "\u6587\u4EF6",
    },
    app: "\u5E94\u7528\u7A0B\u5E8F",
    user: "\u7528\u6237",
    list: "\u5217\u8868",
    edit: "\u7F16\u8F91",
    shop: "\u5546\u5E97",
    blog: "\u535A\u5BA2",
    post: "\u90AE\u653F",
    mail: "\u7535\u5B50\u90AE\u4EF6",
    chat: "\u804A\u5929",
    cards: "\u724C",
    posts: "\u5E16\u5B50",
    create: "\u521B\u5EFA",
    kanban: "\u767B\u673A",
    general: "\u4E00\u822C\u7684",
    banking: "\u94F6\u884C\u4E1A",
    booking: "\u9884\u8BA2",
    profile: "\u8F6E\u5ED3",
    account: "\u5E10\u6237",
    product: "\u4EA7\u54C1",
    invoice: "\u53D1\u7968",
    details: "\u7EC6\u8282",
    checkout: "\u9000\u623F",
    calendar: "\u65E5\u5386",
    analytics: "\u5206\u6790",
    ecommerce: "\u7535\u5B50\u5546\u52A1",
    management: "\u7BA1\u7406",
    menu_level: "\u83DC\u5355\u7EA7\u522B",
    menu_level_2a: "\u83DC\u5355\u7EA7\u522B 2a",
    menu_level_2b: "\u83DC\u5355\u7EA7\u522B 2b",
    menu_level_3a: "\u83DC\u5355\u7EA7\u522B 3a",
    menu_level_3b: "\u83DC\u5355\u7EA7\u522B 3b",
    menu_level_4a: "\u83DC\u5355\u7EA7\u522B 4a",
    menu_level_4b: "\u83DC\u5355\u7EA7\u522B 4b",
    item_disabled: "\u9879\u76EE\u5DF2\u7981\u7528",
    item_label: "\u9879\u76EE\u6807\u7B7E",
    item_caption: "\u9879\u76EE\u6807\u9898",
    item_external_link: "\u9879\u76EE\u5916\u90E8\u94FE\u63A5",
    description: "\u63CF\u8FF0",
    other_cases: "\u5176\u4ED6\u60C5\u51B5",
    item_by_roles: "\u6309\u89D2\u8272\u9010\u9879",
    only_admin_can_see_this_item: "\u53EA\u6709\u7BA1\u7406\u5458\u53EF\u4EE5\u770B\u5230\u8FD9\u4E2A\u9879\u76EE",
};
export default cn;
